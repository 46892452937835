import React, { useState } from "react";
import "./FAQ.css";
import faqdata1 from "./faq-data.json";
import faqapp from "./faq-app.json";
import faqawarding from "./faq-awarding.json";
import faqeligibility from "./faq-eligibility.json";
import FAQCard from "./FAQ-card";
import Header from "../../components/Header/Header";
import HeaderHome from "../../components/HeaderHome/HeaderHome";

export default function FAQ() {
  const [faqs1, setFaqs1] = useState(faqdata1);
  const [faqs2, setFaqs2] = useState(faqeligibility);
  const [faqs3, setFaqs3] = useState(faqapp);
  const [faqs4, setFaqs4] = useState(faqawarding);
  // General
  const toggleFAQ1 = (index1) => {
    setFaqs1(
      faqs1.map((faq1, i1) => {
        if (i1 === index1) {
          faq1.open = !faq1.open;
        } else {
          faq1.open = false;
        }
        return faq1;
      })
    );
  };
  // Eligibility
  const toggleFAQ2 = (index2) => {
    setFaqs2(
      faqs2.map((faq2, i2) => {
        if (i2 === index2) {
          faq2.open = !faq2.open;
        } else {
          faq2.open = false;
        }
        return faq2;
      })
    );
  };
  // Applications
  const toggleFAQ3 = (index3) => {
    setFaqs3(
      faqs3.map((faq3, i3) => {
        if (i3 === index3) {
          faq3.open = !faq3.open;
        } else {
          faq3.open = false;
        }
        return faq3;
      })
    );
  };
  // Awarding
  const toggleFAQ4 = (index4) => {
    setFaqs4(
      faqs4.map((faq4, i4) => {
        if (i4 === index4) {
          faq4.open = !faq4.open;
        } else {
          faq4.open = false;
        }
        return faq4;
      })
    );
  };

  return (
    <div>
      {/* <Header title="" backgroundUrl="Header-General.png" color="white" /> */}
      <HeaderHome backgroundUrl="header-general.png" />
      <div style={{ textAlign: "center", marginTop: "3em" }}>
        <h1
          className="faq-title"
          style={{
            color: "#0260A8",
            fontWeight: "bold",
          }}
        >
          {" "}
          Frequently Asked Questions{" "}
        </h1>
      </div>
      <div className="contact-container faq">
        <p style={{ textAlign: "center" }}>
          Use this page to check if we already have an answer to your question.
        </p>
        <h2 style={{ fontWeight: "bold", paddingTop: "1em" }}>General</h2>
        <div className="faqs">
          {faqs1.map((faq1, index1) => (
            <FAQCard
              faq={faq1}
              index={index1}
              key={index1}
              toggleFAQ={toggleFAQ1}
            />
          ))}
        </div>
        <h2 style={{ fontWeight: "bold", paddingTop: "1em" }}>
          Scholarship Eligibility
        </h2>
        <div className="faqs">
          {faqs2.map((faq2, index2) => (
            <FAQCard
              faq={faq2}
              index={index2}
              key={index2}
              toggleFAQ={toggleFAQ2}
            />
          ))}
        </div>
        <h2 style={{ fontWeight: "bold", paddingTop: "1em" }}>
          Scholarship Applications
        </h2>
        <div className="faqs">
          {faqs3.map((faq3, index3) => (
            <FAQCard
              faq={faq3}
              index={index3}
              key={index3}
              toggleFAQ={toggleFAQ3}
            />
          ))}
        </div>
        <h2 style={{ fontWeight: "bold", paddingTop: "1em" }}>
          Scholarship Awarding
        </h2>
        <div className="faqs">
          {faqs4.map((faq4, index4) => (
            <FAQCard
              faq={faq4}
              index={index4}
              key={index4}
              toggleFAQ={toggleFAQ4}
            />
          ))}
        </div>
        <h3
          style={{ textAlign: "center", fontWeight: "bold", paddingTop: "1em" }}
        >
          Still have a question?
        </h3>
        <p style={{ textAlign: "center" }}>
          If you cannot find an answer to your question in our FAQ, fill out the
          form to send MSF volunteers your question. We will try our best to get
          back to you as soon as possible.
        </p>
        <a href="/contact" style={{ textDecoration: "none" }}>
          <button
            className="contact-button btn btn-primary footer"
            type="button"
          >
            Contact Form
          </button>
        </a>
      </div>
    </div>
  );
}
